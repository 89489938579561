import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
// import LocalStorageServices from "@/core/services/LocalStorageServices";
import { ElNotification } from "element-plus";
import Layout from '@/layout/Layout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"), 
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/admin-dashboard",
        name: "admin-dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      // adding custom route Ashwin B
      /**
       * Route for customer listing
       * Author Ashwin B
       */
      {
        path: "/customers/list",
        name: "customer-list",
        component: () => import("@/views/car/customers/List.vue")

      },
      {
        path: "/customers/add-detail",
        name: "customer-add-detail",
        component: () => import("@/views/car/customers/AddDetailUser.vue")

      },
      {
        path: "/customers/add-bike-detail",
        name: "customer-add-bike-detail",
        component: () => import("@/views/bike/Add.vue")

      },
      {
        path: "/customers/add-new-bike/:customerId",
        name: "customer-add-new-bike",
        component: () => import("@/views/bike/AddToExistingCustomer.vue")

      },
      {
        path: "/customer/details/:customerId",
        name: "customer-details",
        component: () => import("@/views/car/customers/Details.vue"),
      },
      {
        path: "/user/calendar",
        name: "user-calendar",
        component: () => import("@/views/users/Calendar.vue"),
      },
      {
        path: "/lead/driverdetails/add/:driverDetailsId",
        name: "add-driver-details",
        component: () => import("@/views/car/quote/AddDriverDetails.vue"),
      },
      {
        path: "/lead/driverdetails/edit/:driverDetailsId",
        name: "edit-quote",
        component: () => import("@/views/car/quote/EditDriverDetails.vue"),
      },
      {
        path: "/lead/driverdetails/edit-bike/:driverDetailsId",
        name: "edit-bike-quote",
        component: () => import("@/views/bike/Edit.vue"),
      },
      {
        path: "/lead/driverdetails/new-car/:driverDetailsId",
        name: "new-car-quote",
        component: () => import("@/views/car/quote/NewCarDetails.vue"),
      },
      {
        path: "/lead/leaddriverdetails/add/:customerId",
        name: "add-lead-driver-details",
        component: () => import("@/views/car/quote/AddLeadDriverDetails.vue"),
      },
      {
        path: "/lead/quote/compare/:driverDetailsId/:leadId",
        name: "compare-quotes",
        component: () => import("@/views/car/quote/CompareQuote.vue"),
      },
      {
        path: "/lead/generate/quote/add/:customerId",
        name: "generate-manaual-quote",
        component: () => import("@/views/car/manual-quote/GenerateQuotePdf.vue"),
      },
      {
        path: "/lead/generate/driverdetails/quote/add/:driverDetailsId",
        name: "generate-driver-details-manaual-quote",
        component: () => import("@/views/car/manual-quote/GenerateDriverDetailsQuotePdf.vue"),
      },

      /**
       * Routes for Invoices 
       **/
      {
        path: "/lead/invoice/generate/:customerId/:quoteId",
        name: "generate-invoice",
        component: () => import("@/views/car/invoice/GenerateInvoice.vue"),
      },
      {
        path: "/invoice/list",
        name: "invoice-list",
        component: () => import("@/views/car/invoice/InvoiceList.vue"),
      },
      {
        path: "/invoice/split-payments",
        name: "split-payments",
        component: () => import("@/views/car/invoice/SplitPaymentList.vue"),
      },
      {
        path: "/invoice/decline",
        name: "declined-invoice",
        component: () => import("@/views/car/invoice/DeclinedInvoiceList.vue"),
      },
      {
        path: "/invoice/amend/add/:invoiceId",
        name: "add-amend-invoice",
        component: () => import("@/views/car/invoice/AddAmendInvoice.vue"),
      },
      /**
       * Routes for Policies 
       **/
      {
        path: "/policy/assigned",
        name: "policy-assigned",
        component: () => import("@/views/car/policy/PolicyAssigned.vue"),
      },
      {
        path: "/policy/completed",
        name: "policy-completed",
        component: () => import("@/views/car/policy/PolicyCompleted.vue"),
      },
      {
        path: "/invoice/approval",
        name: "policy-approval",
        component: () => import("@/views/car/policy/PolicyApproval.vue"),
      },
      {
        path: "/policy/creator/documents/:policyId",
        name: "upload-policy-documents",
        component: () => import("@/views/car/policy/UploadPolicyDocuments.vue"),
      },
      {
        path: "/policy/cancel-request",
        name: "policy-cancellation-request",
        component: () => import("@/views/car/policy/PolicyCancelRequest.vue"),
      },
      {
        path: "/policy/policy-cancel-approval",
        name: "policy-cancellation-approval",
        component: () => import("@/views/car/policy/PolicyCancelApproval.vue"),
      },
      {
        path: "/policy/cancelled",
        name: "policy-cancelled",
        component: () => import("@/views/car/policy/PolicyCancelled.vue"),
      },
      {
        path: "/policy/refund-closed",
        name: "policy-refund-closed",
        component: () => import("@/views/car/policy/PolicyRefundClosed.vue"),
      },
      {
        path: "/policy/policy-refund-approval",
        name: "policy-refund-approval",
        component: () => import("@/views/car/policy/PolicyRefundApproval.vue"),
      },
      {
        path: "/policy/update-data/:policyId",
        name: "update-data", // route name
        component: () => import("@/views/car/policy/UpdateData.vue"),
      },
      /**
       * Routes for users 
       **/
      {
        path: "/user/list",
        name: "user-list",
        component: () => import("@/views/users/List.vue"),
      },
      {
        path: "/user/create",
        name: "user-create",
        component: () => import("@/views/users/AddUser.vue"),
      },
      {
        path: "/user/edit/:userId",
        name: "user-edit",
        component: () => import("@/views/users/EditUser.vue"),
      },
      /**
       * Routes for Notification 
       **/
      {
        path: "/notification/list",
        name: "notification-list",
        component: () => import("@/views/car/notifications/NotificationList.vue"),
      },
      /**
       * Routes for Inquires 
       **/
      {
        path: "/inquiry/callback-requests",
        name: "callback-requests-list",
        component: () => import("@/views/inquires/CallbackRequestList.vue"),
      },
      {
        path: "/inquiry/quote-others-requests",
        name: "quote-other-requests-list",
        component: () => import("@/views/inquires/CallbackQuoteList.vue"),
      },
      {
        path: "/inquiry/quote-pet-requests",
        name: "quote-pet-requests-list",
        component: () => import("@/views/inquires/CallbackQuotePetList.vue"),
      },
      {
        path: "/inquiry/expat-insurance",
        name: "expat-insurance-inquiries",
        component: () => import("@/views/inquires/ExpatCallBackList.vue"),
      },
      {
        path: "/inquiry/contact-us",
        name: "contact-us-list",
        component: () => import("@/views/inquires/ContactUsList.vue"),
      },
      /**
       * Routes for Inquires 
       **/
      {
        path: "/promotion-code/list",
        name: "promotion-code-list",
        component: () => import("@/views/promotions/List.vue"),
      },
      {
        path: "/promotion-code/create",
        name: "promotion-code-create",
        component: () => import("@/views/promotions/AddPromotionCode.vue"),
      },
      {
        path: "/promotion-code/edit/:id",
        name: "promotion-code-edit",
        component: () => import("@/views/promotions/AddPromotionCode.vue"),
      },
      /**
       * Routes for manual orders 
      **/
      {
        path: "/manual-order/list",
        name: "manual-order-list",
        component: () => import("@/views/car/manual-orders/ManualOrderList.vue"),
      },
      {
        path: "/manual-order/create",
        name: "manual-order-create",
        component: () => import("@/views/car/manual-orders/AddManualOrder.vue"),
      },
      {
        path: "/manual-order/edit/:invoiceId",
        name: "manual-order-edit",
        component: () => import("@/views/car/manual-orders/AddManualOrder.vue"),
      },
      {
        path: "/manual-order/view",
        name: "manual-order-view",
        component: () => import("@/views/car/manual-orders/ManualOrderList.vue"),
      },
      {
        path: "/manual-order/show/:invoiceId",
        name: "manual-order-show",
        component: () => import("@/views/car/manual-orders/AddManualOrder.vue"),
      },
      /**
        * Route for Sales
      **/
      {
        path: "/sales-target/list",
        name: "sales-target-list",
        component: () => import("@/views/car/sales/SalesTargetList.vue"),
      },
      /**
       * Route for Lead
      **/
      {
        path: "/lead-target/list",
        name: "lead-target-list",
        component: () => import("@/views/car/sales/LeadTargetList.vue"),
      },
      /**
       * Route for Quote Requests
       */
      // {
      //   path: "/quote-requests/list",
      //   name: "quote-request-list",
      //   component: () => import("@/views/car/quote-request/QuoteRequests.vue"),
      // },
      
      /**
       * Route for Reports
      **/
       {
        path: "/report/policies-assigned-vs-completed",
        name: "report-policy", // route name
        component: () => import("@/views/reports/policy/PolicyReport.vue"),
      },
      {
        path: "/report/agents",
        name: "report-agent-sales", // route name
        component: () => import("@/views/reports/agents/SalesReport.vue"),
      },
      {
        path: "/report/login-info",
        name: "report-skye-login-info", // route name
        component: () => import("@/views/reports/skye/SkyeLoginInfo.vue"),
      },
      {
        path: "/report/account-master",
        name: "report-account-master", // route name
        component: () => import("@/views/reports/accounts/AccountMaster.vue"),
      },
      {
        path: "/report/approved-invoice",
        name: "report-approved-invoice", // route name
        component: () => import("@/views/reports/invoice/ApprovedInvoice.vue"),
      },
      {
        path: "/report/lead-by-source",
        name: "report-lead-by-source", // route name
        component: () => import("@/views/reports/lead/LeadBySource.vue"),
      },
      {
        path: "/report/closed-leads",
        name: "report-closed-leads", // route name
        component: () => import("@/views/reports/lead/ClosedLeads.vue"),
      },
      {
        path: "/report/deleted-leads",
        name: "report-deleted-leads", // route name
        component: () => import("@/views/reports/lead/DeletedLeads.vue"),
      },
      {
        path: "/report/discrepancy",
        name: "report-discrepancy", // route name
        component: () => import("@/views/reports/discrepancy/Discrepancy.vue"),
      },
      {
        path: "/report/admin-report",
        name: "admin-report", // route name
        component: () => import("@/views/reports/dashboard/AdminDashboard.vue"),
      },
      {
        path: "/report/dashboard-details",
        name: "dashboard-details-report", // route name
        component: () => import("@/views/reports/dashboard/DashboardDetails.vue"),
      },

      {
        path: "/report/production-report",
        name: "al-manarah-report", // route name
        component: () => import("@/views/reports/accounts/AlManarahReport.vue"),
      },
      {
        path: "/report/marketing-report",
        name: "marketing-report", // route name
        component: () => import("@/views/reports/marketing/MarketingReport.vue"),
      },

      {
        path: "/report/marketing-report-agent",
        name: "marketing-report-agent", // route name
        component: () => import("@/views/reports/marketing/MarketingReport.vue"),
      },

      {
        path: "/report/renewal-status",
        name: "renewal-status", // route name
        component: () => import("@/views/NEW/Reports/Views/RenewalStatus.vue"),
      },

      {
        path: "/report/lost-lead-report",
        name: "lost-lead-report", // route name
        component: () => import("@/views/NEW/Reports/Views/LostLead.vue"),
      },

      {
        path: "/report/agent-escalations",
        name: "agent-escalations", // route name
        component: () => import("@/views/NEW/Reports/Views/AgentEscalation.vue"),
      },

      {
        path: "/report/underwriter-escalations",
        name: "underwriter-escalations", // route name
        component: () => import("@/views/NEW/Reports/Views/UnderwriterEscalation.vue"),
      },

      {
        path: "/report/sales-tracking-report",
        name: "sales-tracking-report", // route name
        component: () => import("@/views/NEW/Reports/Views/SalesTrackingReport.vue"),
      },

      {
        path: "/report/agent-lead-activity-report",
        name: "agent-lead-activity-report", // route name
        component: () => import("@/views/NEW/Reports/Views/AgentLeadActivityReport.vue"),
      },

      /**
       * Route For Marketing Campaigns
       */
      {
        path: "/marketing/spend",
        name: "marketing-spend", // route name
        component: () => import("@/views/car/marketing/MarketingSpend.vue"),
      },
      
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: {
      requiresVisitor: true,
      // reload:true
    },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/auth/token/:token",
        name: "fetchToken",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Authentication.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // path: "/sales",
    // component: () => import("@/components/page-layouts/Achievement.vue"),
    path: "/sales/achievements",
    name: "sales-achievements",
    component: () => import("@/views/SalesAchievements.vue"),
    // redirect: 'achievements',
    meta: {
      requiresAuth: true
    },
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/sign-in",
  },
  {
    path: "/switch-app/:switchTo/:refreshToken",
    name: "switch-app",
    component: () => import("@/views/SwitchApp.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});



router.beforeEach((to, from, next) => {
const token = window.localStorage.getItem('id_token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      // window.localStorage.removeItem('currentRoute');
      window.localStorage.removeItem('myRoute');
      window.localStorage.removeItem('id_token');
      window.localStorage.removeItem('refresh_token');
      window.localStorage.removeItem('expires_in');
      window.localStorage.removeItem('user');
        next({
            path: "/sign-in"
        });
        
    } else {
        next();
    }
} else if(to.matched.some(record => record.meta.requiresVisitor)) {
  if(token) {
    next({
      path: '/dashboard'
    })
  } else {
    // window.localStorage.removeItem('currentRoute');
    window.localStorage.removeItem('myRoute');
    window.localStorage.removeItem('id_token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('expires_in');
    window.localStorage.removeItem('user');
    next();
  }
}  else {
  next();
}

setTimeout(() => {
  ElNotification.closeAll();
}, 2000);


  // reset config to initial state

  // console.log(LocalStorageServices.getUser());
  // console.log(store.getters.getUserPermissions);
  // userStr = LocalStorageServices.getUser();
  // userObj = JSON.parse(userStr);
  // if (userObj && userObj.role_id == 2) {
  //   routes = sales_routes;
  // }
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

/**
 * it was commented. 
 * Uncommented by Ashwin B
 */
  // store.dispatch(Actions.VERIFY_AUTH);
  const myRoute = window.localStorage.getItem('myRoute')
  if(!myRoute) {
    const token = window.localStorage.getItem('id_token');
    if(token) {
      store.dispatch(Actions.GET_ROUTE_LIST, { type: "car" });
    }
  }else {
    store.commit(Mutations.SET_ROUTE_LIST, JSON.parse(myRoute))
  }

    // const authToken = window.localStorage.getItem('id_token');
    if(token){
      const tokenCheckedAt = window.localStorage.getItem('token_lc');
      // const tokenCheckedAt = "2024-04-08 12:10:00";
      const tokenTimeLimit = 45;
      /**
       * Checking if the last checked time is past 45 minutes of last check
       */
      const recheckToken   = (tokenCheckedAt) 
                                ? ((new Date()) > (new Date(new Date(tokenCheckedAt).getTime() + tokenTimeLimit * 60 * 1000)))
                                : true;
      if(recheckToken){
        store.dispatch(Actions.GET_NEW_AUTH_CHECK, { token: recheckToken });
      }    
    }
  


  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;