import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { common } from '@/store/stateless/common'
import { leadStatusMixin } from "@/core/plugins/vcMixins";

@Module
export default class Report extends VuexModule {
    get getPoliciesAssignedVsCompleted() {
        return (data) => {
            return data.map(x => {
                return {
                    created_at: x.created_at,
                    quote_ref_no: x.car_lead_quote ? x.car_lead_quote.quote_ref_no : '',
                    status_text: x.status_text ? x.status_text : '',
                    underwriter_name: x.policy_underwriter ? x.policy_underwriter.name : '',
                    sales_agent_name: x.policy_sales_agent ? x.policy_sales_agent.name : '',
                    transaction_type_text: x.transaction_type_text ? x.transaction_type_text : '',
                    bank_transaction_code: x.transaction_type_text && x.transaction_type_text == 'Online' ? x.bank_transaction_code : '-',
                    policy_name: x.car_insurance_policy ? x.car_insurance_policy.policy_name : '',
                    policy_type_text: x.car_insurance_policy ? x.car_insurance_policy.policy_type_text : '',
                    policy_number: x.policy_number ? x.policy_number : '',
                    policy_price: common.formatCurrency(x.policy_price ? x.policy_price : 0),
                    policy_price_vat: common.formatCurrency(x.car_lead_invoice ? x.car_lead_invoice.vat : 0),
                    policy_start_date_format: x.policy_start_date_format ? x.policy_start_date_format : '',
                    policy_end_date_format: x.policy_end_date_format ? x.policy_end_date_format : '',
                    vehicle_details: x.car_lead ? `${x.car_lead.car_year} | ${x.car_lead.model} | ${x.car_lead.car_type} | ${x.car_lead.model_details}` : '',
                    vehicle_value: common.formatCurrency(x.car_lead && x.car_lead.lead_driver_detail ? x.car_lead.lead_driver_detail.car_value : 0)
                }
            })
        }
    }

    get getAgentSales() {
        return (data) => {
            return data.map(x => {
                return {
                    created_at: x.created_at,
                    merchant_reference: x.merchant_reference ? x.merchant_reference : '',
                    created_by_name: x.created_by ? x.created_by.name : '',
                    policy_created_on: x.policy_purchase ? x.policy_purchase.created_at : '',
                    policy_completed_on: x.policy_purchase && x.policy_purchase.issue_date ? x.policy_purchase.issue_date : '',
                    original_price: common.formatCurrency(x.original_price ? x.original_price : 0),
                    original_price_vat: common.formatCurrency(x.original_price_vat ? x.original_price_vat : 0),
                    sale_price:  common.formatCurrency(x.sale_price ? x.sale_price : 0),
                    sale_price_vat: common.formatCurrency(x.vat ? x.vat : 0),
                    discount_amount: common.formatCurrency(x.discount_amount ? x.discount_amount : 0),
                    promo_code: x.promo_code ? x.promo_code.promotion_code : '',
                    transaction_type: x.payment_type_text ? x.payment_type_text : '',
                    policy_sales_agent_name: x.policy_sales_agent ? x.policy_sales_agent.name : '',
                    policy_purchase: x.policy_purchase ? x.policy_purchase.status_text : '',
                    debit_note: common.formatCurrency(x.debit_amount ? x.debit_amount : 0),
                    debit_note_vat: common.formatCurrency(x.debit_amount_vat ? x.debit_amount_vat : 0),
                    cancellation_completed_date: x.policy_purchase ? x.policy_purchase.cancellation_completed_date : '',
                    cancellation_payable_amount: common.formatCurrency(x.policy_purchase ? x.policy_purchase.cancellation_payable_amount : 0),
                    cancellation_payable_amount_vat: common.formatCurrency(x.policy_purchase ? x.policy_purchase.cancellation_payable_amount_vat : 0),
                    cancellation_debit_amount: common.formatCurrency(x.policy_purchase ? x.policy_purchase.cancellation_debit_amount : 0),
                    cancellation_credit_amount: common.formatCurrency(x.policy_purchase ? x.policy_purchase.cancellation_credit_amount : 0),
                    refunded_on: x.policy_purchase && x.policy_purchase.car_refunds && x.policy_purchase.car_refunds[0] ? x.policy_purchase.car_refunds[0].refunded_on : '',
                    total_refund_amount: common.formatCurrency(x.total_refund_amount ? x.total_refund_amount : 0),
                    total_refund_customer: common.formatCurrency(x.total_refund_customer ? x.total_refund_customer : 0),
                    total_refund_agent: common.formatCurrency(x.total_refund_agent ? x.total_refund_agent : 0),
                    total_refund_amount_vat: common.formatCurrency(x.total_refund_amount_vat ? x.total_refund_amount_vat : 0),
                    policy_name: x.policy ? x.policy.policy_name : '',
                    policy_type: x.policy ? x.policy.policy_type_text : '',
                    policy_no: x.policy_purchase ? x.policy_purchase.policy_number : '',
                    policy_start_date: x.policy_purchase ? x.policy_purchase.policy_start_date_format : '',
                    policy_end_date: x.policy_purchase ? x.policy_purchase.policy_end_date_format : '',
                    vehicle_details: x.car_lead ? `${x.car_lead.car_year} | ${x.car_lead.model} | ${x.car_lead.car_type} | ${x.car_lead.model_details}` : '',
                    vehicle_value: common.formatCurrency(x.car_lead && x.car_lead.lead_driver_detail ? x.car_lead.lead_driver_detail.car_value : 0),
                    policy_underwriter_name: x.policy_purchase && x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : ''
                }
            })
        }
    }

    get getAdminReport() {
        return (data) => {
            if(Array.isArray(data)) {
                const total = getTotal(data)
                const removeTotal = data.filter(x => x.name != 'total')
                const result =  Array.from([...removeTotal, ...total])
                return result.map(x => {
                    return {
                        name: x.name,
                        total_sale: common.formatCurrency(x.total_sale, 0),
                        total_leads: common.formatCurrency(x.total_leads, 0),
                        total_closing_ratio: common.formatCurrency(x.total_closing_ratio, 0),
                        total_comp: common.formatCurrency(x.total_comp, 0),
                        total_tpl: common.formatCurrency(x.total_tpl, 0),
                        total_revenue: common.formatCurrency(x.total_revenue),
                        new_sale: common.formatCurrency(x.new_sale, 0),
                        new_leads: common.formatCurrency(x.new_leads, 0),
                        new_closing_ratio: common.formatCurrency(x.new_closing_ratio, 0),
                        new_comp: common.formatCurrency(x.new_comp, 0),
                        new_tpl: common.formatCurrency(x.new_tpl, 0),
                        new_revenue: common.formatCurrency(x.new_revenue),
                        wip_sale: common.formatCurrency(x.wip_sale, 0),
                        wip_comp: common.formatCurrency(x.wip_comp, 0),
                        wip_tpl: common.formatCurrency(x.wip_tpl, 0),
                        wip_revenue: common.formatCurrency(x.wip_revenue),
                        renewal_sale: common.formatCurrency(x.renewal_sale, 0),
                        renewal_leads: common.formatCurrency(x.renewal_leads, 0),
                        renewal_closing_ratio: common.formatCurrency(x.renewal_closing_ratio, 0),
                        renewal_comp: common.formatCurrency(x.renewal_comp, 0),
                        renewal_tpl: common.formatCurrency(x.renewal_tpl, 0),
                        renewal_revenue: common.formatCurrency(x.renewal_revenue),
                        llr_sale: common.formatCurrency(x.llr_sale, 0),
                        llr_leads: common.formatCurrency(x.llr_leads, 0),
                        llr_closing_ratio: common.formatCurrency(x.llr_closing_ratio, 0),
                        llr_comp: common.formatCurrency(x.llr_comp, 0),
                        llr_tpl: common.formatCurrency(x.llr_tpl, 0),
                        llr_revenue: common.formatCurrency(x.llr_revenue),
                        lost_leads: common.formatCurrency(x.lost_leads, 0),
                        lrl_leads: common.formatCurrency(x.lrl_leads, 0),
                        hot_lead: common.formatCurrency(x.hot_lead, 0),
                        return_hot_lead: common.formatCurrency(x.return_hot_lead, 0),
                        work_in_progress: common.formatCurrency(x.work_in_progress, 0),
                        ltr_sale: common.formatCurrency(x.ltr_sale, 0),
                        ltr_leads: common.formatCurrency(x.ltr_leads, 0),
                        ltr_closing_ratio: common.formatCurrency(x.ltr_closing_ratio, 0),
                        ltr_comp: common.formatCurrency(x.ltr_comp, 0),
                        ltr_tpl: common.formatCurrency(x.ltr_tpl, 0),
                        ltr_revenue: common.formatCurrency(x.ltr_revenue),
                        untouched_customers: common.formatCurrency(x.untouched_customers, 0),
                        untouched_renewal_leads: common.formatCurrency(x.untouched_renewal_leads, 0),
                        untouched_new_leads: common.formatCurrency(x.untouched_new_leads, 0),
                        untouched_llr: common.formatCurrency(x.untouched_llr, 0),
                        total_refunds: common.formatCurrency(x.total_refunds, 0),
                        commission_paid_to_agent: common.formatCurrency(x.commission_paid_to_agent, 0),
                        partial_refund: common.formatCurrency(x.partial_refund, 0),
                        full_refund: common.formatCurrency(x.full_refund, 0),
                        total_refund_amount: common.formatCurrency(x.total_refund_amount),
                        partial_refund_amount: common.formatCurrency(x.partial_refund_amount),
                        full_refund_amount: common.formatCurrency(x.full_refund_amount),
                        total_cancellation: common.formatCurrency(x.total_cancellation, 0),
                        partial_cancellation: common.formatCurrency(x.partial_cancellation, 0),
                        full_cancellation: common.formatCurrency(x.full_cancellation, 0),
                        total_cancellation_amount: common.formatCurrency(x.total_cancellation_amount),
                        partial_cancellation_amount: common.formatCurrency(x.partial_cancellation_amount),
                        full_cancellation_amount: common.formatCurrency(x.full_cancellation_amount),
                        total_revenue_inc_addons: common.formatCurrency(x.total_revenue_inc_addons, 0),
                        addons: common.formatCurrency(x.addons, 0)
                    }
                })

            }
            return []
        }

    }

    get getApprovedInvoices() {
        return (data) => {
            return data.map(x => {
                const tSalePrice = x.sale_price ? x.sale_price : 0
                const tSalePriceVat = x.vat ? x.vat : 0
                const sale_price = parseFloat(tSalePrice) + parseFloat(tSalePriceVat)
                const tOriginalPrice = x.original_price ? x.original_price : 0
                const tOriginalPriceVat = x.original_price_vat ? x.original_price_vat : 0
                const policy_price = parseFloat(tOriginalPrice) + parseFloat(tOriginalPriceVat)

                let discrepancy_color = ''
                if(x.discrepancy_amount > 0) discrepancy_color = '#008000'
                if(x.discrepancy_amount < 0) discrepancy_color = '#ff6347'
                return {
                    quote_ref_no: x.merchant_reference,
                    invoice_type: x.invoice_type,
                    invoice_price: common.formatCurrency(sale_price),
                    invoice_price_vat: common.formatCurrency(tSalePriceVat),
                    policy_price: common.formatCurrency(policy_price),
                    policy_price_vat: common.formatCurrency(tOriginalPriceVat),
                    discrepancy_amount: `<span style="color: ${discrepancy_color}"> ${common.formatCurrency(x.discrepancy_amount)} </span>`,
                    status_text: x.status_text,
                    payment_type_text: x.payment_type_text,
                    payment_type_online: x.payment_type && x.payment_type == 1 ? x.bank_transaction_code : '-',
                    policy_underwriter_name: x.policy_purchase && x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : '<div class="text-danger">Not assigned</div>',
                    policy_sales_agent_name: x.policy_sales_agent ? x.policy_sales_agent.name : '-',
                    created_at: x.created_at,
                    created_by_name: x.created_by ? x.created_by.name : '-',
                    invoice_approved_at: x.invoice_approved_at ? x.invoice_approved_at : '-',
                    policy_type: x.policy && x.policy.policy_type && x.policy.policy_type == 4 ? 'Comp' : 'TPL',
                    policy_name: x.policy ? x.policy.policy_name : '-',
                    order_description: x.order_description
                }
            })
        }
    }

    get getAccountMaster() {
        return (data) => {
            return data.map(x => {
                let insurance_payment_text = ''
                let refund_payable_amount_agent = 0
                let refund_payable_amount_customer = 0
                let refund_reason = ''
                let agent_refund_notes = ''
                let refund_created_at = ''
                let refunded_on = ''
                let refunded_by_name = ''
                let totalRefund = 0
                let policy_price = 0
                let policy_price_vat = 0
                if(x.is_amend == 1)
                    insurance_payment_text = x.insurance_payment_text
                else if(x.policy_purchase)
                    insurance_payment_text = x.policy_purchase.insurance_payment_text

                if(x.policy_purchase && x.policy_purchase.car_refunds && x.policy_purchase.car_refunds[0]) {
                    const refundAmounts = x.policy_purchase.car_refunds[0]
                    refund_payable_amount_agent = refundAmounts.refund_payable_amount_agent
                    refund_payable_amount_customer = refundAmounts.refund_payable_amount_customer
                    refund_reason = refundAmounts.refund_reason
                    agent_refund_notes = refundAmounts.refund_note
                    refund_created_at = refundAmounts.created_at
                    refunded_on = refundAmounts.refunded_on

                    totalRefund = parseFloat(refund_payable_amount_agent.toString()) + parseFloat(refund_payable_amount_customer.toString())
                    if(refundAmounts.refunded_by)
                    refunded_by_name = refundAmounts.refunded_by.name
                }
                
                if(x.is_amend == 1) {
                    policy_price = common.formatCurrency(x.amount ? x.amount : 0)
                    policy_price_vat = common.formatCurrency(x.vat ? x.vat : 0)
                } else {
                    policy_price = common.formatCurrency(x.original_price ? x.original_price : 0)
                    policy_price_vat = common.formatCurrency(x.original_price_vat ? x.original_price_vat : 0)
                }

                return {
                    quote_ref_no: x.merchant_reference,
                    invoice_approved_at: x.invoice_approved_at,
                    transaction_type: x.policy_purchase && x.policy_purchase.status == 7 ? `${x.payment_type_text} / ${x.policy_purchase.pay_to_text}` : x.payment_type_text,
                    reference_no: x.reference_no ? x.reference_no : '-',
                    payment_date: x.payment_date ? x.payment_date : '-',
                    transaction_code: x.payment_type == 1 ? x.bank_transaction_code : '-',
                    customer_name: x.customer ? x.customer.name : '',
                    policy_purchase_at: x.policy_purchase ? x.policy_purchase.created_at : '',
                    policy_purchase_issue_at: x.policy_purchase ? x.policy_purchase.issue_date : '',
                    policy_status_text: x.policy_purchase ? x.policy_purchase.status_text : '',
                    insurance_payment_text,
                    policy_start_date_format: x.policy_purchase ? x.policy_purchase.policy_start_date_format : '',
                    policy_end_date_format: x.policy_purchase ? x.policy_purchase.policy_end_date_format : '',
                    policy_underwriter_name: x.policy_purchase && x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : '',
                    policy_number: x.policy_purchase ? x.policy_purchase.policy_number : '',
                    policy_name: x.policy ? x.policy.policy_name : '',
                    company_name: x.policy && x.policy.insurance_provider ? x.policy.insurance_provider.provider_name : '',
                    policy_type_text: x.policy ? x.policy.policy_type_text : '',
                    policy_price: policy_price,
                    policy_price_vat: policy_price_vat,
                    sale_price: common.formatCurrency(x.sale_price ? x.sale_price : 0),
                    sale_price_vat: common.formatCurrency(x.vat ? x.vat : 0),
                    discount_amount: common.formatCurrency(!x.coupon_id && x.discount_amount ? x.discount_amount : 0),
                    promotion_code: x.promo_code ? x.promo_code.promotion_code : '',
                    discount_amount_code_value: common.formatCurrency(x.promo_code ? x.promo_code.code_value : 0),
                    credit_amount: common.formatCurrency(x.credit_amount ? x.credit_amount : 0),
                    credit_amount_vat: common.formatCurrency(x.credit_amount_vat ? x.credit_amount_vat : 0),
                    debit_amount: common.formatCurrency(x.debit_amount ? x.debit_amount : 0),
                    debit_amount_vat: common.formatCurrency(x.debit_amount_vat ? x.debit_amount_vat : 0),
                    discrepancy_amount: common.formatCurrency(x.discrepancy_amount ? x.discrepancy_amount : 0),
                    amend_type_text: x.amend_type_text ? x.amend_type_text : '',
                    amendment_amount: common.formatCurrency(x.amend_type == 0 ? x.amendment_amount : x.sale_price),
                    cancellation_type_text: x.policy_purchase ? x.policy_purchase.cancellation_type_text : '',
                    cancellation_amount: x.policy_purchase && x.policy_purchase.cancellation_payable_amount ? common.formatCurrency(x.policy_purchase.cancellation_payable_amount) : '',
                    cancellation_credit_amount: x.policy_purchase && x.policy_purchase.cancellation_credit_amount ? common.formatCurrency(x.policy_purchase.cancellation_credit_amount) : '',
                    cancellation_credit_amount_vat: x.policy_purchase && x.policy_purchase.cancellation_credit_amount_vat ? common.formatCurrency(x.policy_purchase.cancellation_credit_amount_vat) : '',
                    cancellation_debit_amount: x.policy_purchase && x.policy_purchase.cancellation_debit_amount ? common.formatCurrency(x.policy_purchase.cancellation_debit_amount) : '',
                    cancellation_debit_amount_vat: x.policy_purchase && x.policy_purchase.cancellation_debit_amount_vat ? common.formatCurrency(x.policy_purchase.cancellation_debit_amount_vat) : '',
                    cancellation_reason_text: x.policy_purchase ? x.policy_purchase.cancellation_reason_text : '',
                    cancellation_note: x.policy_purchase ? x.policy_purchase.cancellation_note : '',
                    underwriter_cancellation_notes: x.policy_purchase ? x.policy_purchase.underwriter_cancellation_notes : '',
                    cancellation_request_date: x.policy_purchase ? x.policy_purchase.cancellation_request_date : '',
                    cancellation_completed_date: x.policy_purchase ? x.policy_purchase.cancellation_completed_date : '',
                    cancellation_completed_by_name: x.policy_purchase && x.policy_purchase.cancellation_completed_by_name ? x.policy_purchase.cancellation_completed_by_name.name : '',
                    refund_type_text: x.policy_purchase && x.policy_purchase.car_refunds && x.policy_purchase.car_refunds[0] ? x.policy_purchase.car_refunds[0].refund_type_text : '',
                    refund_amount: x.refund_amount > 0 ? common.formatCurrency(totalRefund) : '',
                    refund_reason,
                    agent_refund_notes,
                    refund_created_at,
                    refunded_on,
                    refunded_by_name,
                    car_refunds: x.policy_purchase ? setCarRefunds(x.policy_purchase.car_refunds) : [],
                    car_year: x.car_lead ? x.car_lead.car_year : '',
                    car_make: x.car_lead ? x.car_lead.make : '',
                    car_model: x.car_lead ? x.car_lead.model : '',
                    model_details: x.car_lead ? x.car_lead.model_details : '',
                    first_registration_date_format: x.car_lead ? x.car_lead.first_registration_date_format : '',
                    car_value: x.car_lead && x.car_lead.lead_driver_detail ? common.formatCurrency(x.car_lead.lead_driver_detail.car_value) : '',
                    registration_emirate_text: x.car_lead ? x.car_lead.registration_emirate_text : '',
                    lead_source: x.car_lead ? x.car_lead.lead_source : '',
                    policy_sales_agent_name: x.policy_sales_agent ? x.policy_sales_agent.name : ''
                }
            })
        }
    }

    get getDiscrepancy() {
        return (data) => {
            return data.map(x => {
                return {
                    created_at: x.created_at ? x.created_at : '',
                    merchant_reference: x.merchant_reference ? x.merchant_reference : '',
                    created_by_name: x.created_by ? x.created_by.name : '',
                    original_price: common.formatCurrency(x.original_price ? x.original_price : 0),
                    original_price_vat: common.formatCurrency(x.original_price_vat ? x.original_price_vat : 0),
                    sale_price: common.formatCurrency(x.sale_price ? x.sale_price : 0),
                    sale_price_vat: common.formatCurrency(x.vat ? x.vat : 0),
                    discount_amount: x.discount_amount ? common.formatCurrency(x.discount_amount) : '',
                    promotion_code: x.promo_code ? x.promo_code.promotion_code : '',
                    payment_type_text: x.payment_type_text ? x.payment_type_text : '',
                    policy_sales_agent_name: x.policy_sales_agent ? x.policy_sales_agent.name : '',
                    policy_underwriter_name: x.policy_purchase && x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : '',
                    discrepancy_amount: x.discrepancy_amount ? common.formatCurrency(x.discrepancy_amount) : '',
                    discrepancy_status: x.discrepancy_status ? x.discrepancy_status : '',
                    discrepany_approved_by_name: x.discrepany_approved_by ? x.discrepany_approved_by.name : '',
                    discrepancy_approved_at: x.discrepancy_approved_at ? x.discrepancy_approved_at : '',
                    credit_amount: x.credit_amount ? common.formatCurrency(x.credit_amount) : '',
                    credit_amount_vat: x.credit_amount_vat ? common.formatCurrency(x.credit_amount_vat) : '',
                    debit_amount: x.debit_amount ? common.formatCurrency(x.debit_amount) : '',
                    debit_amount_vat: x.debit_amount_vat ? common.formatCurrency(x.debit_amount_vat) : '',
                    policy_purchase_text: x.policy_purchase ? x.policy_purchase.status_text : '',
                    policy_name: x.policy ? x.policy.policy_name : '',
                    policy_type: x.policy ? x.policy.policy_type_text : '',
                    policy_price: x.latest_policy_purchase ? common.formatCurrency(x.latest_policy_purchase.policy_price) : '',
                    policy_number: x.policy_purchase ? x.policy_purchase.policy_number : '',
                    policy_start_date_format: x.policy_purchase ? x.policy_purchase.policy_start_date_format : '',
                    policy_end_date_format: x.policy_purchase ? x.policy_purchase.policy_end_date_format : '',

                }
            })
        }
    }

    get getDashboardDetail() {
        return (data) => {
            return data.map(x => {
                return {
                    customer_id: x.customer ? x.customer.id : '',
                    customer_name: x.customer ? x.customer.name : '',
                    customer_email: x.customer ? x.customer.email : '',
                    customer_phone: x.customer ? x.customer.phone_number : '',
                    lead_source: x.lead_source ? x.lead_source : '',
                    lead_score: x.lead_score ? x.lead_score : '',
                    lead_status: x.lead_status_id ? `<span class="badge" style="${leadStatusMixin(x.lead_status_id)}">${x.lead_status_text}</span>` : '-',
                    sales_agent: x.agent_details ? x.agent_details.name : '',
                    other_lead_source: x.other_sources_count_by ? getAllObjectKeyAndValues(x.other_sources_count_by) : '',
                    utm_source: x.utm_source ? x.utm_source : '',
                    utm_medium: x.utm_medium ? x.utm_medium : '',
                    other_utm_medium: x.other_utm_medium_count_by ? getAllObjectKeyAndValues(x.other_utm_medium_count_by) : '',
                    utm_campaign: x.utm_campaign ? x.utm_campaign : '',
                    other_utm_campaign: x.other_utm_campaign_count_by ? getAllObjectKeyAndValues(x.other_utm_campaign_count_by) : '',
                    utm_content: x.utm_content ? x.utm_content : '',
                    other_utm_content: x.other_utm_content_count_by ? getAllObjectKeyAndValues(x.other_utm_content_count_by) : '',
                    lead_id: x.id ? x.id : '',
                    vehicle_year: x.car_year ? x.car_year : '',
                    car_make: x.make ? x.make : '',
                    car_model: x.model ? x.model : '',
                    car_trim: x.model_details ? x.model_details : '',
                    policy_type: x.latest_policy_purchase && x.latest_policy_purchase.car_insurance_policy ? x.latest_policy_purchase.car_insurance_policy.policy_type_text : '-',
                    policy_price: x.latest_policy_purchase && x.latest_policy_purchase.policy_price ? common.formatCurrency(x.latest_policy_purchase.policy_price) : '',
                    created_on: x.created_at ? x.created_at : '',
                    updated_on: x.updated_at_date_format ? x.updated_at_date_format : '',


                }
            })
        }
    }

    get getLoginInfo() {
        return (data) => {
            return data.map(x => {
                let location = ''
                if(x.latitude && x.longitude)
                location = `${x.latitude}, ${x.longitude}`

                return {
                    user_name: x.user ? x.user.name : '',
                    location,
                    type_of_machine: x.machine_type ? x.machine_type : '',
                    ip: x.ip_address ? x.ip_address : '',
                    browser_type: x.browser_type ? x.browser_type : '',
                    login_date_time: x.login_date_time_format ? x.login_date_time_format : '',
                    logout_date_time: x.logout_date_time_format ? x.logout_date_time_format : '',
                    type_of_logout: x.logout_type ? x.logout_type : '',
                    is_active: x.isActive == 1 ? 'Yes' : 'No'
                }
            })
        }
    }

    get getClosedLeads() {
        return (data) => {
            return data.map(x => {
                let lead_sources = x.lead_source ? x.lead_source : ''
                if(x.duplicate_source)
                    lead_sources = `${x.lead_source}, ${x.duplicate_source}`

                return {
                    leads_created_at: x.created_at ? x.created_at : '',
                    leads_assigned_to: x.carlead_new_status_log && x.carlead_new_status_log.agent_details ? x.carlead_new_status_log.agent_details.name : '',
                    lead_first_year_status: x.lead_first_year_status ? x.lead_first_year_status : '',
                    lead_first_year_agent: x.lead_first_year_agent ? x.lead_first_year_agent : '',
                    lead_second_year_status: x.lead_second_year_status ? x.lead_second_year_status : '',
                    lead_second_year_agent: x.lead_second_year_agent ? x.lead_second_year_agent : '',
                    lead_closed_lead: x.carlead_closed_status_log ? x.carlead_closed_status_log.created_at : '',
                    lead_closed_lead_by: x.carlead_closed_status_log && x.carlead_closed_status_log.action_by ? x.carlead_closed_status_log.action_by.name : '',
                    lead_sources,
                    customer_name: x.customer ? x.customer.name : '',
                    customer_email: x.customer ? x.customer.email : '',
                    customer_phone_number: x.customer ? x.customer.phone_number : '',
                    car_detail_name: x.car_detail_name ? x.car_detail_name : '',
                    car_value: x.lead_driver_detail && x.lead_driver_detail.car_value ? common.formatCurrency(x.lead_driver_detail.car_value) : '-',
                    
                }
            })
        }
    }

    get getDeletedLeads() {
        return (data) => {
            return data.map(x => {
                let deleted_reson = x.latest_task ? x.latest_task.lost_lead_reason_text : ''
                if(x.latest_task && x.latest_task.lost_lead_reason_id == 1)
                    deleted_reson = `${deleted_reson} ${x.latest_task.disposition_text}`

                let lead_sources = x.lead_source ? x.lead_source : ''
                if(x.duplicate_source)
                    lead_sources = `${x.lead_source}, ${x.duplicate_source}`


                return {
                    leads_created_at: x.created_at ? x.created_at : '',
                    leads_assigned_to: x.agent_details ? x.agent_details.name : '',
                    lead_status_text: x.lead_status_text ? x.lead_status_text : '',
                    deleted_reson,
                    deleted_at: x.deleted_at ? x.deleted_at : '',
                    deleted_by: x.deleted_by ? x.deleted_by.name : '',
                    lead_sources,
                    customer_name: x.customer ? x.customer.name : '',
                    customer_email: x.customer ? x.customer.email : '',
                    customer_phone_number: x.customer ? x.customer.phone_number : '',
                    car_detail_name: x.car_detail_name ? x.car_detail_name : '',
                    car_value: x.lead_driver_detail ? common.formatCurrency(x.lead_driver_detail.car_value) : '',
                    other_lead_source: x.other_sources_count_by ? getAllObjectKeyAndValues(x.other_sources_count_by) : '',
                    utm_source: x.utm_source ? x.utm_source : '',
                    other_utm_source: x.other_sources_count_by ? getAllObjectKeyAndValues(x.other_utm_source_count_by) : '',
                    utm_medium: x.utm_medium ? x.utm_medium : '',
                    other_utm_medium: x.other_utm_medium_count_by ? getAllObjectKeyAndValues(x.other_utm_medium_count_by) : '',
                    utm_campaign: x.utm_campaign ? x.utm_campaign : '',
                    other_utm_campaign: x.other_utm_campaign_count_by ? getAllObjectKeyAndValues(x.other_utm_campaign_count_by) : '',
                    utm_content: x.utm_content ? x.utm_content : '',
                    other_utm_content: x.other_utm_content_count_by ? getAllObjectKeyAndValues(x.other_utm_content_count_by) : '',
                }
            })
        }
    }

    get getProductionReport() {
        return (data) => {
            return data.map(x => {
                return {
                    created_at: x.created_at ? x.created_at : '',
                    requested_date: x.requested_date ? x.requested_date : '',
                    user_name: x.user ? x.user.name : '',
                    url: x.url ? x.url : ''
                }
            })
        }
    }

    get getRenewalStatus() {
        return (data) => {
            
            return data.map(x => {
                x.car_details= x.car_year +' | '+x.make +' | '+x.model +' | '+x.trim
                x.policy_expiry_date = common.setDateNTOnly( x.policy_expiry_date) 
                x.plan = x.insurance_plan ? x.insurance_plan + ' - ' + x.policy_sub_type : ''
                return x
            })
        }

    }

    get getLostLeadReport() {
        return (data) => {
            return data.map(x => {
                return {
                    customer_id : x.customer_id,
                    customer_name : x.customer_name,
                    lead_source :  x.lead_source,
                    lead_status : x.lead_status_text,
                    agent : x.agent_name,
                    lead_id : x.id,
                    id : x.id,
                    lead_lost_date : common.setDate(x.lost_lead_date),
                    reason : x.lost_lead_reason_text,
                    notes : x.task_notes,
                    car_details: x.car_year +' | '+x.make +' | '+x.model +' | '+x.model_details,
                    name: x.car_year +' | '+x.make +' | '+x.model +' | '+x.model_details,
                    car_year: x.car_year,
                    make: x.make,
                    model: x.model,
                    created_at : common.setDate(x.created_at),
                    updated_at : common.setDate(x.updated_at),
                }
            })
        }

    }

    get getLostLeadStatReport() {
        return (data) => {
            const lostLeads = data.reduce((acc: { [key: string]: number }, obj: any) => {
                if (obj.lead_status_id === 6) {
                    // Get the reason from the object
                    const reason = obj.lost_lead_reason_text
                    
                    // Check if reason is valid
                    if (reason) {
                        // Use Object.prototype.hasOwnProperty.call to safely check and update the accumulator
                        if (Object.prototype.hasOwnProperty.call(acc, reason)) {
                            acc[reason]++;
                        } else {
                            acc[reason] = 1;
                        }
                    }
                }
                
                return acc;
            }, {});

            const lrl = data.reduce((acc: { [key: string]: number }, obj: any) => {
                if (obj.lead_status_id === 8) {
                    // Get the reason from the object
                    const reason = obj.lost_lead_reason_text
                    
                    // Check if reason is valid
                    if (reason) {
                        // Use Object.prototype.hasOwnProperty.call to safely check and update the accumulator
                        if (Object.prototype.hasOwnProperty.call(acc, reason)) {
                            acc[reason]++;
                        } else {
                            acc[reason] = 1;
                        }
                    }
                }
                
                return acc;
            }, {});

            return  [
                createGraph('Lost Leads',Object.values(lostLeads),Object.keys(lostLeads)),
                createGraph('LRL',Object.values(lrl),Object.keys(lrl)),
            ]


            // console.log(lostLeads,lrl )

        }

    }

    get getAgentEscalations() {
        return (data) => {
            
            return data.map(x => {
                return x
            })
        }

    }

    get getUnderwriterEscalations() {
        return (data) => {
            
            return data.map(x => {
                return x
            })
        }

    }
    
    get getSalesTrackingReport() {
        return (data) => {
            return data.map(x => {
                return {
                    name : x.name,
                    el_total_revenue : x.el_total_revenue,
                    el_upto_revenue_target :  x.el_upto_revenue_target,
                    el_percentage : x.el_percentage,
                    el_deficit : x.el_deficit,
                    el_rank : x.el_rank,
                    el_total_target : x.el_total_target,
                    el_policies_issued : x.el_policies_issued,
                    el_upto_policy_target : x.el_upto_policy_target,
                    el_policy_target : x.el_policy_target,
                    el_policy_percentage : x.el_policy_percentage,
                    el_total_required : x.el_total_required,
                    el_perday_required : x.el_perday_required
                }
            })
        }
    }

    get getSalesTeamReport() {
        return (data) => {
            return data.map(x => {
                return {
                    name : x.name,
                    el_total_target : x.el_total_target,
                    el_total_revenue :  x.el_total_revenue,
                    el_upto_revenue_target : x.el_upto_revenue_target,
                    el_percentage : x.el_percentage,
                    el_percentage_total_achievement : x.el_percentage_total_achievement,
                    el_perday_required : x.el_perday_required,
                    el_perday_earned : x.el_perday_earned,
                    el_earning_rate_for_month : x.el_earning_rate_for_month,
                    el_deficit : x.el_deficit,
                }
            })
        }
    }

    get getAgentLeadActivityReport() {
        return (data) => {
            return data.map(x => {
                return x
            })
        }
    }


}

function createGraph(title,series, labels) {
    return {
        "title":title,
        "series": series,
        "options": {
            "chart": {
                "width": 500,
                "height": 350,
                "type": "pie"
            },
            // "colors": colors,
            "labels": labels,
            "responsive": [{
                "breakpoint": 400,
                "options": {
                    "chart": {
                        "width": 400,
                        "height": 250,
                    },
                    "legends": {
                        "position": "bottom"
                    }
                } 
            }]
        }
    }
}


function calculateTotal(data, column) {
    return data.reduce((total, item) => {
            if(column  == 'revenue_target'){
                item[column] = item[column].replaceAll(',','');
            } 
            if(typeof item[column] === 'string') {
                const value = item[column].replace(',', '')
                return total += parseFloat(value)
            } else {
                return total += item[column]
            }
    }, 0)
}

function roundUp(num) {
    const p = Math.pow(10, 2);
    return Math.round(num * p) / p;
}

function getTotal(data) {
    const total_sale  = calculateTotal(data, 'total_sale')
    const total_leads  = calculateTotal(data, 'total_leads')
    const total_closing_ratio = roundUp((total_sale / total_leads) * 100)
    const new_sale  = calculateTotal(data, 'new_sale')
    const new_leads  = calculateTotal(data, 'new_leads')
    const new_closing_ratio = roundUp((new_sale / new_leads) * 100)
    const renewal_sale  = calculateTotal(data, 'renewal_sale')
    const renewal_leads  = calculateTotal(data, 'renewal_leads')
    const renewal_closing_ratio = roundUp((renewal_sale / renewal_leads) * 100)
    const llr_sale  = calculateTotal(data, 'llr_sale')
    const llr_leads  = calculateTotal(data, 'llr_leads')
    const llr_closing_ratio = roundUp((llr_sale / llr_leads) * 100)
    const ltr_sale  = calculateTotal(data, 'ltr_sale')
    const ltr_leads  = calculateTotal(data, 'ltr_leads')
    const ltr_closing_ratio = roundUp((ltr_sale / ltr_leads) * 100)
   

    return [{
        name: 'Total',
        total_sale,
        total_leads,
        total_closing_ratio,
        total_comp: calculateTotal(data, 'total_comp'),
        total_tpl: calculateTotal(data, 'total_tpl'),
        total_revenue: calculateTotal(data, 'total_revenue'),
        new_sale,
        new_leads,
        new_closing_ratio,
        new_comp: calculateTotal(data, 'new_comp'),
        new_tpl: calculateTotal(data, 'new_tpl'),
        new_revenue: calculateTotal(data, 'new_revenue'),
        wip_sale: calculateTotal(data, 'wip_sale'),
        wip_comp: calculateTotal(data, 'wip_comp'),
        wip_tpl: calculateTotal(data, 'wip_tpl'),
        wip_revenue: calculateTotal(data, 'wip_revenue'),
        renewal_sale,
        renewal_leads,
        renewal_closing_ratio,
        renewal_comp: calculateTotal(data, 'renewal_comp'),
        renewal_tpl: calculateTotal(data, 'renewal_tpl'),
        renewal_revenue: calculateTotal(data, 'renewal_revenue'),
        llr_sale,
        llr_leads,
        llr_closing_ratio,
        llr_comp: calculateTotal(data, 'llr_comp'),
        llr_tpl: calculateTotal(data, 'llr_tpl'),
        llr_revenue: calculateTotal(data, 'llr_revenue'),
        lost_leads: calculateTotal(data, 'lost_leads'),
        lrl_leads: calculateTotal(data, 'lrl_leads'),
        hot_lead: calculateTotal(data, 'hot_lead'),
        return_hot_lead: calculateTotal(data, 'return_hot_lead'),
        work_in_progress: calculateTotal(data, 'work_in_progress'),
        ltr_sale,
        ltr_leads,
        ltr_closing_ratio,
        ltr_comp: calculateTotal(data, 'ltr_comp'),
        ltr_tpl: calculateTotal(data, 'ltr_tpl'),
        ltr_revenue: calculateTotal(data, 'ltr_revenue'),
        untouched_customers: calculateTotal(data, 'untouched_customers'),
        untouched_renewal_leads: calculateTotal(data, 'untouched_renewal_leads'),
        untouched_new_leads: calculateTotal(data, 'untouched_new_leads'),
        untouched_llr: calculateTotal(data, 'untouched_llr'),
        total_refunds: calculateTotal(data, 'total_refunds'),
        commission_paid_to_agent: calculateTotal(data, 'commission_paid_to_agent'),
        partial_refund: calculateTotal(data, 'partial_refund'),
        full_refund: calculateTotal(data, 'full_refund'),
        total_refund_amount: calculateTotal(data, 'total_refund_amount'),
        partial_refund_amount: calculateTotal(data, 'partial_refund_amount'),
        full_refund_amount: calculateTotal(data, 'full_refund_amount'),
        total_cancellation: calculateTotal(data, 'total_cancellation'),
        partial_cancellation: calculateTotal(data, 'partial_cancellation'),
        full_cancellation: calculateTotal(data, 'full_cancellation'),
        total_cancellation_amount: calculateTotal(data, 'total_cancellation_amount'),
        partial_cancellation_amount: calculateTotal(data, 'partial_cancellation_amount'),
        full_cancellation_amount: calculateTotal(data, 'full_cancellation_amount'),
        total_revenue_inc_addons: calculateTotal(data, 'total_revenue_inc_addons'),
        addons: calculateTotal(data, 'addons')
    }]
}

function getAllObjectKeyAndValues(obj) {
    if(obj) {
        const arr = <Array<string>>[]
        for(const [key, value] of Object.entries(obj))
            arr.push(`${key} (${value})`)

        return arr.join(', ')
    }

    return
}

function setCarRefunds(data) {
    return data.map(x => {
        const refund_payable_amount_agent = x.refund_payable_amount_agent ? x.refund_payable_amount_agent : 0
        const refund_payable_amount_customer = x.refund_payable_amount_customer ? x.refund_payable_amount_customer : 0
        const refund_type_amount = refund_payable_amount_agent + refund_payable_amount_customer
        return {
            refund_type_text: x.refund_type_text ? x.refund_type_text : '',
            refund_type_amount: common.formatCurrency(refund_type_amount),
            refund_reasons_text: x.refund_reasons_text ? x.refund_reasons_text : '',
            refund_note: x.refund_note ? x.refund_note : '',
            created_at: x.created_at ? x.created_at : '',
            refunded_on: x.refunded_on ? x.refunded_on : '',
            refunded_by_name: x.refunded_by ? x.refunded_by.name : '',
        }
    })
}