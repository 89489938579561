/* eslint-disable no-useless-escape */

class Validations {
    public static email_taken = false;
    public static is_reference_no_exist = false
    public static is_doc_limit = false
    public static is_password_used = false
    public static user_name_not_found = false;
    public static user_name_not_active = false;
    public static unauthorized = false;
    public static code_not_valid = false;
    public static code_expired = false;
    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter email'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else if(Validations.email_taken) {
            callback(new Error('Email address aleardy exist!'));
        }
        else {
            callback();
        }
    }

    public static password(rule: any, value: any, callback: any) {
            
        if(!value) {
            callback(new Error('Password is required'));
          } else if(value.length < 8) {
            callback(new Error('Password minimum of 8 characters'));
          } else {
            callback();
          }
    }

    public static passwordStrong(rule: any, value: any, callback: any) {
        const lengthRequirement = /.{12,}/ // At least 12 characters
        const uppercaseRequirement = /[A-Z]/ // At least one uppercase letter
        const lowercaseRequirement = /[a-z]/ // At least one lowercase letter
        const digitRequirement = /\d/ // At least one digit
        const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/ // At least one special character
    
        // Check each requirement
        const isLengthValid = lengthRequirement.test(value)
        const hasUppercase = uppercaseRequirement.test(value)
        const hasLowercase = lowercaseRequirement.test(value)
        const hasDigit = digitRequirement.test(value)
        const hasSpecialChar = specialCharRequirement.test(value)

        if(!value) {
            callback(new Error('Password is required'))
        //   } else if(value.length < 12) {
        //     callback(new Error('Password minimum of 12 characters'))
          } else if (value.length < 8 && !isLengthValid || !hasUppercase || !hasLowercase || !hasDigit || !hasSpecialChar) {
            callback(new Error('Password is too weak'))
          } else if (value.toLowerCase().includes('pass') || value.toLowerCase().includes('123') || value.toLowerCase().includes('p@ss')) {
            callback(new Error('Password must not contain a word pass or number 123'))
          } else if(Validations.is_password_used) {
            callback(new Error('Password already used'))
          } else {
            callback()
          }
    }

    public static username(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Username required'))
        } else if(Validations.user_name_not_found) {
            callback(new Error('Username not found'))
        } else if(Validations.unauthorized) {
            callback(new Error('Unauthorized access'))
        } else if(Validations.user_name_not_active) {
            callback(new Error('Username not active.'))
        } else {
            callback()
        }
    }

    public static confirm_password(rule: any, value: any, callback: any, password: string) {
        if(!value) {
            callback(new Error('Confirm password is required'))
          } else if(value.length < 12) {
            callback(new Error('Confirm password minimum of 12 characters '))
          } else if (value != password) {
            callback(new Error('Password and confirm password not match'))
          } else {
            callback()
          }
    }

    public static code(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Enter code'))
        } else if(Validations.code_not_valid) {
            callback(new Error('Code is not valid'))
        } else if(Validations.code_expired) {
            callback(new Error('Code already expired'))
        } else {
            callback()
        }
    }

    public static names(rule: any, value: any, callback: any) {
        const isvalidtext = /^[a-zA-Zء-ي\s'-]+$/;
        const singleCount = value ? value.match(/[']/g) : false;
        const minusCount = value ? value.match(/[-]/g) : false;

        let singleCountChecker = true;
        let minusCountChecker = true;

        if(singleCount) {
            singleCountChecker = singleCount.length > 1 ? false : true
        }
        if(minusCount) {
            minusCountChecker = minusCount.length > 1 ? false : true
        }

        if(!value) {
            callback(new Error('Please enter full name'));
        } else if(!isvalidtext.test(String(value).toLowerCase()) || !singleCountChecker || !minusCountChecker) {
            callback(new Error(`Only character a-z, ء-ي, - and ' allowed`))
        } else {
            callback();
        }
    }

    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter phone number'));
        } else if(value.length < 8 || value.length > 10) {
            callback(new Error('Phone number must not be less than 8 digits'));
        }
         else {
            callback();
        }
    }

    public static lead_source(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Lead source is required'));
        }  else {
            callback();
        }
    }

    public static dob(rule: any, value: any, callback: any) {
        
        if(value === null || value === '') {
            callback(new Error('Please enter date of birth'));
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the gender'));
        } else {
            callback();
        }
    }
    public static is_brand_new(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }
    public static is_policy_expired(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }
    public static is_claimed_insurance(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }

    public static nationality(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the nationality'));
        } else {
            callback();
        }
    }
    public static first_driving_license_country(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select country'));
        } else {
            callback();
        }
    }

    public static age_group(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Age group is required'));
        } else {
            callback();
        }
    }

    public static insured_city(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the visa issued emirate'));
        } else {
            callback();
        }
    }

    public static company_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter company name'));
        } else {
            callback();
        }
    }

    public static agent(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select sales agent'));
        } else {
            callback();
        }
    }

    public static notes(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Notes is required'));
        } else {
            callback();
        }
    }

    public static expected_insurance_start_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter expected insurance start date'));
        } else {
            callback();
        }
    }

    public static is_salary(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select about your salary'));
        } else {
            callback();
        }
    }

    public static is_self(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select about your insurance'));
        } else {
            callback();
        }
    }
    public static car_year(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select  year'));
        } else {
            callback();
        }
    }
    public static bike_trim(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select engine capacity'));
        } else {
            callback();
        }
    }
    public static car_value(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter  value'));
        } else {
            callback();
        }
    }
    public static registration_emirate(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select emirate'));
        } else {
            callback();
        }
    }
    public static first_registration_date(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please select registration date'));
        } else {
            callback();
        }
    }
    public static policy_start_date(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please select policy start date'));
        } else {
            callback();
        }
    }
    public static driving_experience(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please select driving experience'));
        } else {
            callback();
        }
    }
    public static uae_driving_experience(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please select driving experience'));
        } else {
            callback();
        }
    }
    public static no_of_claims(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please enter no of claims'));
        } else {
            callback();
        }
    }
    public static no_claim_certificate(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please select certificate'));
        } else {
            callback();
        }
    }

    public static invoice_doc(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please upload file for COD / Direct'));
        }  else if(value && Validations.is_doc_limit) {
            callback(new Error('Document size exceed limit of 2mb'))
        } else {
            callback();
        }
    }

    public static order_description(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter order description'));
        } 
        else {
            callback();
        }
    }

    public static reference_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter reference no'));
        } else if(Validations.is_reference_no_exist) {
            callback(new Error('Reference no. already exist'))
        }else {
            callback();
        }
    }

    public static payment_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter payment date'));
        } 
        else {
            callback();
        }
    }

}
/**
 * create custom validations
 * @created vincent carabbacan
 */
export default Validations;